import React from "react";

const Quote = () => {
  return (
    <main
      id="quotePg"
      className=" p-5"
      style={{ minHeight: "calc(100vh - 150px)" }}
    >
      <div className="overlay"></div>
      <section className="text-center p-0 mt-5 mx-auto container-fluid pt-5">
        <div className="page-title">
          <h2 className="text-capitalize text-secondary fw-bold display-2">
            Request Quote
          </h2>
          <p className="lead text-myWhite mt-2 fs-3 mx-auto mb-5">
            We've got the best hands for the job.
          </p>
        </div>
        <form action="" id="quote-form" className="p-5 mx-auto">
          <fieldset className="p-4 mx-auto mb-4">
            <legend className="mx-auto fs-4 text-bold text-start text-sm-center p-0 my-0">
              Personal Details:
            </legend>
            <div className="row justify-content-center justify-content-md-between text-start p-0 gx-2 gy-1 g-lg-3 mx-auto">
              <div className="col-12 col-sm-6 col-lg-4 my-1">
                <div className="d-flex flex-column">
                  <label htmlFor="name" title="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4 my-1">
                <div className="d-flex flex-column">
                  <label htmlFor="contact" title="contact">
                    Contact Number:
                  </label>
                  <input
                    type="tel"
                    name="contact"
                    id="contact"
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4 my-1">
                <div className="d-flex flex-column">
                  <label htmlFor="email" title="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4 my-1">
                <div className="d-flex flex-column">
                  <label htmlFor="address" title="address">
                    Street Address:
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <small className="text-secondary fst-italic mt-3 d-block mx-auto">
              * All fields in this block are required.
            </small>
          </fieldset>

          <fieldset className="p-4 mx-auto mb-4">
            <legend className="mx-auto fs-4 text-bold text-start text-sm-center p-0 my-0">
              Property Details:
            </legend>
            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Building/ Apartment Size:</p>
              <div className="">
                <div>
                  <div className="">
                    <input
                      type="number"
                      name="property_size"
                      className="form-control d-inline"
                      placeholder="Size in square meters"
                      style={{ maxWidth: "fit-content" }}
                    />
                    <label for="single">sqm</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Property Type:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="residential"
                      name="property_type"
                      value="residential"
                    />
                    <label for="residential">residential</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="commercial"
                      name="property_type"
                      value="commercial"
                    />
                    <label for="commercial">commercial</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="other"
                      name="property_type"
                      value="other/ not sure"
                    />
                    <label for="other">other/ not sure</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Property Style:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="single"
                      name="property_style"
                      value="single storey"
                    />
                    <label for="single">single storey</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="double"
                      name="property_style"
                      value="double storey"
                    />
                    <label for="double">double storey</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="other"
                      name="property_style"
                      value="other/ not sure"
                    />
                    <label for="other">other/ not sure</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Flooring Type:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="tiled"
                      name="flooring"
                      value="tiled floors"
                    />
                    <label for="tiled">tiled floors</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="carpeted"
                      name="flooring"
                      value="carpeted floors"
                    />
                    <label for="carpeted">carpeted floors</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="tile_carpet"
                      name="flooring"
                      value="tile and carpet"
                    />
                    <label for="tile_carpet">tile and carpet</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="other"
                      name="flooring"
                      value="other / not sure"
                    />
                    <label for="other">other / not sure</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Do you have ceilings higher than 2.7m?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="high_ceilings_yes"
                    name="high_ceilings"
                    value="yes"
                  />
                  <label for="high_ceilings_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="high_ceilings_no"
                    name="high_ceilings"
                    value="no"
                  />
                  <label for="high_ceilings_no">no</label>
                </div>
              </div>
            </div>

            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Number of Rooms:</p>
              <div className="">
                <div className="row justify-content-center justify-content-md-between text-start p-0 gx-2 gy-1 g-lg-3 mx-auto">
                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="name" title="name">
                        en-suite bedrooms
                      </label>
                      <input
                        type="number"
                        name="en-suites"
                        id="en-suites"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="contact" title="contact">
                        normal bedrooms:
                      </label>
                      <input
                        type="number"
                        name="bedrooms"
                        id="bedrooms"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="email" title="email">
                        normal bathrooms:
                      </label>
                      <input
                        type="number"
                        name="bathrooms"
                        id="bathrooms"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="address" title="address">
                        Kitchens:
                      </label>
                      <input
                        type="number"
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="address" title="address">
                        living & lounge areas:
                      </label>
                      <input
                        type="number"
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="address" title="address">
                        guest toilets:
                      </label>
                      <input
                        type="number"
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="address" title="address">
                        scullery & laundries :
                      </label>
                      <input
                        type="number"
                        name="scullery_laundries"
                        id="scullery_laundries"
                        className="form-control"
                        placeholder="Enter number"
                      />
                    </div>
                  </div>

                  <div className="col-12 my-1">
                    <div className="d-flex flex-column">
                      <label htmlFor="address" title="address">
                        other:
                      </label>
                      <textarea
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Specify use of rooms and cleaning requirements."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="p-4 mx-auto mb-4">
            <legend className="mx-auto fs-4 text-bold text-start text-sm-center p-0 my-0">
              Scope and Nature of Cleaning Service:
            </legend>
            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Property Type:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="standard"
                      name="service"
                      value="standard"
                    />
                    <label for="standard">standard</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="deep"
                      name="service"
                      value="deep cleaning"
                    />
                    <label for="deep">deep cleaning</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="move_in"
                      name="service"
                      value="move in"
                    />
                    <label for="move_in">move in</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="move_out"
                      name="service"
                      value="move out"
                    />
                    <label for="move_out">move out</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="post_construction"
                      name="service"
                      value="post construction"
                    />
                    <label for="post_construction">post construction</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="post_renovation"
                      name="service"
                      value="post renovation"
                    />
                    <label for="post_renovation">post renovation</label>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="d-flex flex-column">
                      <label for="custom">Request Custom service:</label>
                      <textarea
                        id="custom"
                        name="service"
                        className="form-control"
                        placeholder="Detail your service requirements."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Optional Extras:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="laundry_ironing"
                      name="extra_services"
                      value="laundry & ironing"
                    />
                    <label for="laundry_ironing">laundry & ironing</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="carpet"
                      name="extra_services"
                      value="carpet cleaning"
                    />
                    <label for="carpet">carpet cleaning</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="windows_interior"
                      name="extra_services"
                      value="windows interior"
                    />
                    <label for="windows_interior">windows interior</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="interior_walls"
                      name="extra_services"
                      value="interior walls"
                    />
                    <label for="interior_walls">interior walls</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="cabinets"
                      name="extra_services"
                      value="inside cabinets"
                    />
                    <label for="cabinets">inside cabinets</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="oven"
                      name="extra_services"
                      value="inside oven"
                    />
                    <label for="oven">inside oven</label>
                  </div>
                  <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="fridge"
                      name="extra_services"
                      value="inside fridge"
                    />
                    <label for="fridge">inside fridge</label>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="d-flex flex-column">
                      <label for="other">other:</label>
                      <textarea
                        id="other"
                        name="extra_services"
                        className="form-control"
                        placeholder="Specify special cleaning requirements: Example: Post wedding cleanup for 120 guests."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Are there any specific areas that require special attention?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="special_attention_areas_yes"
                    name="special_attention_areas"
                    value="yes"
                  />
                  <label for="special_attention_areas_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="no_special_attention_areas_no"
                    name="special_attention_areas"
                    value="no"
                  />
                  <label for="special_attention_areas_no">no</label>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                  <label for="special_attention_details">details:</label>
                  <textarea
                    id="special_attention_details"
                    name="special_attention_details"
                    className="form-control"
                    placeholder="Detail your service requirements."
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Are there any specific products anyone in your household is
                  allergic to?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="allergens_yes"
                    name="allergens"
                    value="yes"
                  />
                  <label for="allergens_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="allergens_no"
                    name="allergens"
                    value="no"
                  />
                  <label for="allergens_no">no</label>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                  <label for="allergens_details">details:</label>
                  <textarea
                    id="allergens_details"
                    name="allergens_details"
                    className="form-control"
                    placeholder="Detail all allergens to protect your loved ones."
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Do you have any specific cleaning materials or equipment
                  on-site or should we provide everything?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="onsite_equipment_and_materials_yes"
                    name="onsite_equipment_and_materials"
                    value="yes"
                  />
                  <label for="onsite_equipment_and_materials_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="onsite_equipment_and_materials_no"
                    name="onsite_equipment_and_materials"
                    value="no"
                  />
                  <label for="onsite_equipment_and_materials_no">no</label>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                  <label for="onsite_equipment_and_materials_details">
                    details:
                  </label>
                  <textarea
                    id="onsite_equipment_and_materials_details"
                    name="onsite_equipment_and_materials_details"
                    className="form-control"
                    placeholder="Detail supplied materials and equipment (if any)."
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Do you have any specific cleaning materials or equipment
                  on-site or should we provide everything?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input type="radio" id="pets_yes" name="pets" value="yes" />
                  <label for="pets_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input type="radio" id="pets_no" name="pets" value="no" />
                  <label for="pets_no">no</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <div className="d-flex flex-row p-0 align-items-center">
                    <label for="number_of_pets" className="p-0 mb-0">
                      How many:
                    </label>
                    <input
                      type="number"
                      id="number_of_pets"
                      name="pets"
                      className="form-control ms-2"
                      style={{ maxWidth: "30%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                  <label for="previous_service_flaws_details">
                    Do you have any pain-points or concerns about your previous
                    cleaning service?
                  </label>
                  <textarea
                    id="previous_service_flaws"
                    name="previous_service_flaws"
                    className="form-control"
                    placeholder="Provide your concerns (if any)."
                    rows={1}
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="p-4 mx-auto mb-4">
            <legend className="mx-auto fs-4 text-bold text-start text-sm-center p-0 my-0">
              Booking Details:
            </legend>
            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">Cleaning frequency:</p>
              <div className="">
                <div className="row">
                  <div className="col-12 p-0 ps-5 p-md-0">
                    <div className="d-flex flex-row align-items-center mb-1">
                      <input
                        type="radio"
                        id="once_off"
                        name="cleaning_frequency"
                        value="once-off"
                      />
                      <label for="once_off pb-0">once-off</label>

                      <input
                        type="date"
                        id="booking_date"
                        name="booking_date"
                        className="form-control ms-2 ms-md-3 d-inline"
                        style={{ maxWidth: "40%" }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="daily"
                      name="cleaning_frequency"
                      value="daily"
                    />
                    <label for="daily">daily</label>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="weekly"
                      name="cleaning_frequency"
                      value="weekly"
                    />
                    <label for="weekly">weekly</label>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="bi_weekly"
                      name="cleaning_frequency"
                      value="bi-weekly"
                    />
                    <label for="bi_weekly">bi-weekly</label>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="monthly"
                      name="cleaning_frequency"
                      value="monthly"
                    />
                    <label for="monthly">monthly</label>
                  </div>
                  <div className="col-12 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="monthly"
                      name="cleaning_frequency"
                      value="monthly"
                    />
                    <label for="monthly">monthly</label>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="d-flex flex-column">
                      <label for="preferred_times">
                        Do you have preferred days or times?
                      </label>
                      <textarea
                        id="preferred_times"
                        name="preferred_times"
                        className="form-control"
                        placeholder="Example: 12h00 Fridays"
                        rows={1}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <div>
                <p className="heading sentence">
                  Will someone be present during the cleaning service?
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="person_onsite_yes"
                    name="person_onsite"
                    value="yes"
                  />
                  <label for="person_onsite_yes">yes</label>
                </div>
                <div className="col-12 col-md-4 p-0 ps-5 p-md-0">
                  <input
                    type="radio"
                    id="person_onsite_no"
                    name="person_onsite"
                    value="no"
                  />
                  <label for="person_onsite_no">no</label>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex flex-column">
                  <label for="onsite_access_details">
                    How do we get access to the premises (if no-none is
                    present):
                  </label>
                  <textarea
                    id="onsite_access_details"
                    name="onsite_access_details"
                    className="form-control"
                    placeholder="Specify access instructions."
                    rows={1}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="text-start p-0 gx-2 gy-1 g-lg-3 mx-auto py-3 border-bottom border-1 border-secondary">
              <p className="heading">
                What is your preferred method of payment?
              </p>
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="card"
                      name="payment_method"
                      value="card"
                    />
                    <label for="card">card</label>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="EFT"
                      name="payment_method"
                      value="EFT"
                    />
                    <label for="EFT">EFT</label>
                  </div>
                  <div className="col-12 col-md-3 p-0 ps-5 p-md-0">
                    <input
                      type="radio"
                      id="cash"
                      name="payment_method"
                      value="cash"
                    />
                    <label for="cash">cash</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <button
            type="submit"
            className="btn btn-lg btn-secondary text-primary fw-bold "
          >
            Request Quote
          </button>
        </form>
      </section>
    </main>
  );
};

export default Quote;
