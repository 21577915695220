import React from "react";
import HeroSectionWithCta from "../components/HeroSectionWithCta";
import About from "../components/About";
import { Services } from "../components/Services";
import BnbAd from "../components/BnbAd";
import { Link } from "react-router-dom";
import Contact from "../components/Contact";
function Home() {
  return (
    <>
      <HeroSectionWithCta />
      <About />
      <Services />
      <div className="py-5">
        <section id="yardPageBreak" className=" pageBreak mx-auto text-center">
          <div className="">
            <h3 className="text-secondary display-1 fw-bold">
              Trusted Cleaning Specialists
            </h3>
            <p className="fs-3 mt-2 text-myWhite mx-auto text-center">
              Let us take care of your most valuable asset.
            </p>
            <Link
              to="/quote"
              className="btn btn-tertiary rounded-pill fw-semibold py-2 px-5 mt-4 mb-4 mx-auto"
              style={{ width: "fit-content" }}
            >
              Request Quote
            </Link>
          </div>
        </section>
      </div>
      <BnbAd />
      {/* <PromoRight /> */}
      <section
        id="windowPageBreak"
        className="pageBreak mx-auto d-none d-md-block"
      ></section>
      <Contact />
    </>
  );
}

export default Home;
