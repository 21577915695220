import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="container-fluid text-center py-3 mx-auto bg-secondary">
      <div className="footer-social-icons">
        {/* <Link to="#" target="_blank" className='fs-3 me-3' style={{color: "#909090"}}><i className="fab fa-facebook"></i></Link> */}
        {/* <Link to="#" target="_blank" className='fs-3 me-3' style={{color: "#909090"}}><i className="fab fa-instagram"></i></Link> */}
        {/* <Link to="#" target="_blank" className='fs-3 me-3' style={{color: "#909090"}}><i className="fab fa-linkedin"></i></Link> */}
        {/* <Link to="#" target="_blank" className='fs-3' style={{color: "#909090"}}><i className="fab fa-youtube"></i></Link> */}
      </div>
      <span className="d-block pt-2">
        © {year} All rights reserved
        <br className="d-sm-none" /> Khumo Housekeeping Services.{" "}
      </span>
      <Link
        className="tcg d-block text-tertiary text-decoration-none py-2"
        to="https://www.tiberiuscg.co.za/"
        style={{ cursor: "pointer" }}
      >
        <small> Developed by</small>
        <strong> TiberiusCG</strong>
      </Link>
    </footer>
  );
}

export default Footer;
