import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <section id="About" className="text-center text-md-start mx-auto my-5">
      <div className="container-fluid row align-items-center justify-content-evenly px-0 mx-auto py-5">
        <div className="col-12 col-md-5">
          <div className="about-details p-3 pb-0">
            <h2 className="fs-4 text-secondary fw-bold lead mb-4 text-uppercase">
              About us
              <br />
              <span className="text-capitalize text-primary fw-bold display-3">
                Reclaim Your Time
              </span>
            </h2>
            <p>
              Khumo Housekeeping was born out of a genuine desire to help busy
              individuals reclaim their time. As the pace of life accelerates,
              more and more people find themselves juggling work, family, and
              other commitments. Which results in basic home cleanliness and
              care becoming an “ unfulfilling and time-wasting” compared to
              spending quality time with your family.
            </p>
            <p>
              What makes us more than an ordinary cleaning and household
              services company is our commitment to protecting your most priced
              asset and making it look and feel like the a home you can be proud
              of.
            </p>
            <Link
              to="mailto:info@khumohousekeeping.co.za"
              target="blank"
              className="cta-btn center btn btn-secondary fs-5 py-2 px-4 mt-3 text-myWhite"
            >
              Book Now
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-5 mt-5 mt-md-0 p-0  about-img">
          {/* <img src="../images/window-cleaning.png" alt="Khumo housekeeping window cleaning service" style={{maxWidth: "100%"}}/> */}
        </div>
      </div>
    </section>
  );
}

export default About;
