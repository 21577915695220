import React from "react";
import { Link } from "react-router-dom";

function MainNav() {
  return (
    <nav
      id="MainNav"
      className="navbar navbar-expand-md fixed-top py-4 py-md-3 px-4 container-fluid mx-auto"
    >
      <div className="container-fluid mx-auto">
        <Link className="navbar-brand d-none d-sm-block m-0" to="/">
          <img
            src={require("../images/logo.png")}
            alt="Khumo Housekeeping logo"
            width="100"
          />
        </Link>
        <button
          className="navbar-toggler border-0 ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon fs-2"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto align-items-center">
            <a className="nav-link" href="/">
              Home
            </a>
            <a className="nav-link" href="/#About">
              About Us
            </a>
            <a className="nav-link" href="/#Services">
              Services
            </a>
            <a className="nav-link" href="/#ContactUs">
              Contacts
            </a>
            {/* <a className="nav-link" href="#Careers" >Careers</a>
              <a className="nav-link" href="#Blog" >Blog</a> */}
            <Link
              to="/quote"
              id="quote-link"
              className="btn rounded-pill text-myWhite shadow bg-secondary px-4 mx-auto ms-md-5 me-md-0 mt-5 mt-md-0"
            >
              Get Quote
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainNav;
