import React from "react";

const Faqs = () => {
  return (
    <div id="faqs" className="mt-5 mb-md-5 mx-auto shadow">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              1. What areas do you service?
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              We offer cleaning and housekeeping services to all suburbs and
              towns in and around Polokwane, Pretoria, Johannesburg and Cape
              Town.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              2. What types of cleaning services do you offer?
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              We offer residential, commercial, post-construction, and
              move-in/out cleaning services. We also offer tailor made solutions
              such as guesthouse and AirBnB housekeeping services.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              3. How do I book an appointment?
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              There are multiple ways to book a cleaning service: <br />
              <br />
              Call: Simply call{" "}
              <a
                className="inline text-secondary"
                href="tel:0720539036"
                target="blank"
                rel="nonreferrer"
              >
                {" "}
                0720539036
              </a>{" "}
              and an agent will walk you through the quote and booking process.{" "}
              <br />
              <br />
              Online: submit a quote form on the website and a friendly agent
              will call you back as soon as your quote is ready and has been
              emailed to you.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading4">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse4"
              aria-expanded="false"
              aria-controls="flush-collapse4"
            >
              4. What are your service hours?
            </button>
          </h2>
          <div
            id="flush-collapse4"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading4"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Our operating hours Monday to Friday are 7h00 to 17h00 and
              Saturdays are 7h00 to 14h00. For emergency or special arrangements
              contact{" "}
              <a
                className="inline text-secondary"
                href="tel:0720539036"
                target="blank"
                rel="nonreferrer"
              >
                {" "}
                0720539036
              </a>
              . Additional fees are applicable for special arrangements and
              emergency cleaning services.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading5">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse5"
              aria-expanded="false"
              aria-controls="flush-collapse5"
            >
              5. What payment methods do you accept?
            </button>
          </h2>
          <div
            id="flush-collapse5"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading5"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              All our cleaning and housekeeping services require upfront payment
              before the work starts. We take EFT (with proof of payment), cash
              and debit cards on site.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading6">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse6"
              aria-expanded="false"
              aria-controls="flush-collapse6"
            >
              6. Do you offer any discounts or packages?
            </button>
          </h2>
          <div
            id="flush-collapse6"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading6"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              We offer signup and repetition discounts to our customers. Terms
              and conditions apply.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading7">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse7"
              aria-expanded="false"
              aria-controls="flush-collapse7"
            >
              7. Do you bring your own cleaning supplies?
            </button>
          </h2>
          <div
            id="flush-collapse7"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading7"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Our fees include cleaning supplies, but you are welcome to request
              that we use products and equipment you provide onsite.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading8">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse8"
              aria-expanded="false"
              aria-controls="flush-collapse8"
            >
              8. Do I need to be home during the cleaning?
            </button>
          </h2>
          <div
            id="flush-collapse8"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading8"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              No, you do not need to be home, but please make the arrangements
              in advance to ensure we have access to be able to provide our
              services.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading9">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse9"
              aria-expanded="false"
              aria-controls="flush-collapse9"
            >
              9. When can I cancel my housekeeping service?
            </button>
          </h2>
          <div
            id="flush-collapse9"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading9"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              You can cancel your booked cleaning service for a full refund 24hr
              prior to the start of the service. A full service fee is charge
              for failure to cancel your service 24hr prior to the start time of
              the service or in the event that access arrangements fail upon
              arrival on site.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading10">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapse10"
              aria-expanded="false"
              aria-controls="flush-collapse10"
            >
              10. What happens if I'm not satisfied with the cleaning?
            </button>
          </h2>
          <div
            id="flush-collapse10"
            className="accordion-collapse collapse"
            aria-labelledby="flush-heading10"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Contact our office within 24 hours post cleaning service to submit
              a complaint which details what you expected versus what you got.
              After a successful complaint we will send out our team to correct
              or reclean all missed areas free of charge. If it takes more than
              72 hours to fix the error you will earn a free cleaning service
              equivalent to the one you ordered.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
