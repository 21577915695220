import React, { useState } from "react";
import ContactUs from "./ContactUs";
import Faqs from "./Faqs";

const Contact = () => {
  const [faqs, setFaqs] = useState(false);

  const showFaqs = () => {
    const faqBtn = document.querySelector("#faqBtn");
    setFaqs(!faqs);
    if (faqs) {
      faqBtn.innerHTML = "FAQs";
    } else {
      faqBtn.innerHTML = "Contacts";
    }
  };
  return (
    <section
      id="ContactUs"
      className="text-center p-0 mt-5 mx-auto container-fluid pt-5"
    >
      <h2 className="text-capitalize text-secondary fw-bold display-2">
        Contact Us
      </h2>
      <p className="lead mt-2 fs-4 mx-auto">
        Let’s connect, we are here for you. Check{" "}
        <span
          onClick={showFaqs}
          id="faqBtn"
          className="text-secondary fw-semibold"
        >
          FAQs
        </span>
      </p>

      <div
        id="contacts&form"
        className="container-fluid row p-0 px-md-3 mx-auto"
      >
        <div className="col p-0">{!faqs ? <ContactUs /> : <Faqs />}</div>
      </div>

      <aside className="container-fluid p-0 mt-md-5 mx-auto">
        <iframe
          title="Khumo Housekeeping Location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11759.686859384352!2d29.46488146016249!3d-23.909057827527953!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ec6d827df115513%3A0xd97cb7b2ad5ee58!2s53%20Landdros%20Mare%20St%2C%20Polokwane%20Central%2C%20Polokwane%2C%200700!5e0!3m2!1sen!2sza!4v1660607186381!5m2!1sen!2sza"
          style={{ border: "0", height: "350px" }}
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </aside>
    </section>
  );
};

export default Contact;
