import React from "react";
import { Link } from "react-router-dom";
import "../js/gsap";

function HeroSectionWithCta() {
  return (
    <section id="HeroSectionWithCta" className=" d-flex align-items-end">
      <div className="container-fluid p-0 ">
        <div className="cta-details p-5">
          <span className="text-myWhite text-uppercase fs-5 fw-bold d-sm-none shadow">
            Khumo Housekeeping
          </span>
          <h1 className="display-1 fw-semibold text-secondary">
            Professional Housekeepers
          </h1>
          <p className="fs-5 mt-2 text-myWhite d-none d-sm-block mx-auto w-75">
            We are about taking care of your home. Whether you need a one-time
            deep cleaning service or require regular landscaping care, we have a
            custom solution that will suit your home or commercial needs.
          </p>
          <Link
            to="mailto:info@khumohousekeeping.co.za"
            className="btn btn-tertiary rounded-pill fw-semibold py-2 px-5 mt-4 mb-4 mx-auto"
            style={{ width: "fit-content" }}
          >
            Book Now
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeroSectionWithCta;
