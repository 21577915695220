import React from "react";
import { Link } from "react-router-dom";

function BnbAd() {
  return (
    <section id="BnbAd" className=" my-md-5 py-4 mx-auto">
      <div className="container-fluid row align-items-stretch justify-content-center text-center text-md-start mx-auto px-0">
        <div className="col-12 col-md-6 col-lg-5 px-4 py-3 py-md-5 d-flex flex-column justify-content-center">
          <div className="promo-details pb-5 pb-md-0">
            <h3 className="text-capitalize text-primary fw-bold display-4 mb-4">
              {/* <span className="fs-4 text-secondary fw-bold lead mb-4 text-capitalize">
                Exclusively for
                <br />
              </span> */}
              AirBnB and Bookings.com hosts
            </h3>
            <p>
              We provide specialized housekeeping services that allow you to
              manage your rental like a 5-star hotel, guaranteeing a great
              rating and that your property is always pristine and ready for new
              guests.
            </p>

            <Link
              to="mailto:info@khumohousekeeping.co.za"
              target="blank"
              className="cta-btn center btn btn-tertiary fs-5 py-2 px-4 mt-4 mx-auto fw-bold"
              style={{ width: "fit-content" }}
            >
              Enquire Now
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-4 p-0 BnbAd-img"></div>
      </div>
    </section>
  );
}

export default BnbAd;
