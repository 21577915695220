import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_mvbzz2n", "template_ou1r1pj", form.current, {
        publicKey: "VGqPu5MGVMFltiOYL",
      })
      .then(
        () => {
          document.querySelector("#successLine").innerHTML =
            "Your query was submitted successfully";
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section id="ContactUs" className=" px-0 px-md-2 mt-5 mx-auto">
      <div
        className="container-fluid row align-items-center justify-content-center px-0"
        style={{ maxWidth: "1000px" }}
      >
        <div className="col-12 col-md-6 pb-5 pb-md-0 px-0">
          <div id="contact-details">
            <a
              className="contact-item"
              href="https://www.google.com/maps/place/53+Landdros+Mare+St,+Polokwane+Central,+Polokwane,+0700/@-23.91059,29.45264,17z/data=!3m1!4b1!4m5!3m4!1s0x1ec6d827df115513:0xd97cb7b2ad5ee58!8m2!3d-23.91059!4d29.45264"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-location-dot"></i>
              <div>
                <h4>Address</h4>
                <p>
                  53 Landros Mare Street, Nedbank Building, Office Suite 408,
                  Polokwane, 7000
                </p>
              </div>
            </a>

            <a className="contact-item" href="tel:0720539036">
              <i className="fa-solid fa-phone"></i>
              <div>
                <h4>Phone</h4>
                <p>072 053 9036</p>
              </div>
            </a>

            <a
              className="contact-item"
              href="https://wa.me/27720539036"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-whatsapp"></i>
              <div>
                <h4>WhatsApp</h4>
                <p>072 053 9036</p>
              </div>
            </a>

            <a
              className="contact-item"
              href="mailto:info@khumohousekeeping.co.za"
            >
              <i className="fa-solid fa-envelope"></i>
              <div>
                <h4>Email</h4>
                <p>info@khumohousekeeping.co.za</p>
              </div>
            </a>
          </div>
        </div>

        <div className="col-12 col-md-6 px-0">
          <div className="mb-0">
            <form
              ref={form}
              className="form p-5 bg-primary mx-auto shadow"
              onSubmit={(e) => handleSubmit}
            >
              <div className="name-surname d-block d-md-flex mx-auto justify-content-center gap-0 gap-md-3">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Last Name"
                  required
                />
              </div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                className="email d-block mx-auto"
                required
              />
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                className="d-block mx-auto"
              />
              <textarea
                name="query"
                id="query"
                placeholder="How can we serve you?"
                className="d-block mx-auto"
                required
              ></textarea>
              <input type="submit" className="btn fw-bolder" />
            </form>
            <p
              id="successLine"
              className=" mt-2 fw-semibold fs-5 d-inline text-center text-success"
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
