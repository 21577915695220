import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import MainNav from "./components/MainNav.js";
import Footer from "./components/Footer.js";
import NotFound404 from "./components/NotFound404.js";
import Home from "./pages/Home";
import Quote from "./pages/Quote.js";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MainNav />
        <div id="Home" className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quote" element={<Quote />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
