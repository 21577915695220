import React from "react";

export const Services = () => {
  return (
    <section id="Services" className="text-center px-2 my-5 mx-auto">
      <h2 className="text-capitalize text-primary fw-bold display-2">
        Our Services
      </h2>

      <p className="mt-4 px-3 mx-auto fs-5" style={{ maxWidth: "85%" }}>
        Khumo Housekeeping offers you a wide range of cleaning and other
        housekeeping services for both residential and commercial customers in
        and around Cape Town, Pretoria and Polokwane. We guarantee reliable and
        consistent services delivery and our team of experienced service
        providers are dedicated to providing you with the best possible service.
      </p>

      <div className="service-icons container-fluid row justify-content-center align-items-center gap-3 mt-5 mx-auto">
        <div className="col-3">
          <i className="fa-solid fa-house-circle-check text-secondary"></i>
          <p className="fs-6 fs-lg-5 fw-bold fw-md-bolder text-semibold text-capitalize">
            Residential Cleaning
          </p>
        </div>
        <div className="col-3">
          <i className="fa-solid fa-building-circle-check text-secondary"></i>
          <p className="fs-6 fs-lg-5 fw-bold fw-md-bolder text-semibold text-capitalize">
            Commercial Cleaning
          </p>
        </div>
        <div className="col-3">
          <i className="fa-solid fa-mountain text-secondary"></i>
          <p className="fs-6 fs-lg-5 fw-bold fw-md-bolder text-semibold text-capitalize">
            installations & care
          </p>
        </div>
      </div>
    </section>
  );
};
